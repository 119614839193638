:root {
    --primary-color: #279A0E;
}

.p-tag {
    border-radius: 4px;
}

.p-button {
    gap: 5px;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: #279A0E !important;
    border-color: #279A0E !important;
}

.p-button.p-button-outlined {
    color: #279A0E;
    background-color: transparent !important;
    border: 2px solid #279A0E;
}

.p-button:focus,
.p-checkbox-input:focus,
.p-checkbox-input:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}

.bg-none,
.bg-none:hover,
.bg-none:focus {
    border: 0px solid #fff !important;
    background: transparent !important;
    outline: none !important;
    box-shadow: none !important;
}

/* Check box */
.p-checkbox.p-highlight .p-checkbox-box {
    border-color: #279A0E;
    background: #279A0E;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
    border-color: #279A0E;
}

/* Datatable */
table.p-datatable-table {
    border-collapse: initial !important;
}

/* Allow text selection for the entire DataTable */
.p-datatable,
.p-datatable .p-datatable-tbody td,
.p-datatable .p-datatable-thead th {
    user-select: text;
}

.p-resizable-column {
    border-bottom: 0;
    padding: 10px 16px;
    border-right: 1px solid #eaecf0;
    background: linear-gradient(0deg, #eaecf0, #eaecf0 1px, #fff 0, #fff);
}

.p-datatable-thead .p-column-title {
    color: #424242;
    font-size: 12px;
    font-weight: 400;
}

.p-datatable-thead .p-sortable-column-icon {
    width: 10px;
}

.p-datatable-tbody tr td {
    font-size: 14px;
    color: #424242;
    padding: 10px 16px;
    border-bottom: 1px solid #eaecf0 !important;
    border-right: 1px solid #eaecf0 !important;
}

.p-datatable-tbody tr:nth-child(odd) td {
    background-color: #F5F5F5;
}

.p-datatable .p-datatable-tbody tr.p-highlight td {
    background-color: #EAF5E7;
}

.p-datatable-emptymessage td {
    background-color: #fff !important;
}

/* tiered menu overlay */
.p-tieredmenu.p-tieredmenu-overlay {
    padding: 4px;
    margin-left: -20px;
    width: max-content;
    border-radius: 4px !important;
}

.p-tieredmenu.p-tieredmenu-overlay ul {
    padding-left: 0px;
    margin-bottom: 0px;
}

.p-tieredmenu.p-tieredmenu-overlay .p-tieredmenu .p-menuitem {
    border-radius: 4px;
    width: 100%;
}

.p-tieredmenu.p-tieredmenu-overlay .p-menuitem-content {
    background: transparent !important;
}

.p-tieredmenu.p-tieredmenu-overlay .p-menuitem-content:hover {
    background: #f8f9fa !important;
}

.p-tieredmenu.p-tieredmenu-overlay a {
    text-decoration: none !important;
}

/* Multi select */
.p-multiselect {
    outline: none !important;
    box-shadow: none !important;
}

.p-multiselect-items.p-component {
    padding-left: 0px;
}

.p-multiselect .p-multiselect-label {
    font-size: 14px;
    padding: 8px 10px;
}

.p-multiselect-item {
    font-size: 14px;
}

.p-multiselect-filter-container {
    width: 200px;
}

.p-multiselect-filter.p-inputtext.p-component {
    padding: 5px 1.85rem 5px 10px;
    outline: none !important;
    box-shadow: none !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #279A0E;
    background-color: #EAF5E7;
}

.p-multiselect-panel {
    max-width: min(300px, 100%);
    width: inherit !important;

    .p-multiselect-items .p-multiselect-item span {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        width: inherit !important;
    }
}

/* dropdown */
.p-dropdown-label {
    font-size: 14px;
    padding: 8px 10px;
}

.p-dropdown {
    width: 100%;
    padding: 0px;
    outline: none !important;
    box-shadow: none !important;
}

.p-dropdown-items {
    padding-left: 0px;
    margin-bottom: 0px;
}

.p-dropdown-item {
    height: 48px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.p-focus,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #279A0E;
    background-color: #EAF5E7;
}

.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled):active {
    box-shadow: none;
    border-color: #279A0E;
}

.p-dropdown-panel {
    max-width: min(300px, 100%);
    width: inherit !important;

    .p-dropdown-items .p-dropdown-item {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        width: inherit !important;
    }
}

.p-button:focus,
.p-inputtext:focus,
.p-input-icon:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}

.p-tooltip {
    background-color: #424242;
    color: none;
    border-radius: 4px;
    padding: 0px;
    font-size: 14px;
    -webkit-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;

    .p-tooltip-text {
        padding: 5px;
        background-color: rgba(40, 154, 14, 0.500);
        opacity: 1;
        -webkit-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
}