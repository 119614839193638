@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$primary: #279A0E;
$danger: #E53935;

@import "~bootstrap/scss/bootstrap";

* {
  font-family: "Poppins", serif;
  font-optical-sizing: auto;
}

.cursor-pointer {
  cursor: pointer;
}

body {
  padding: 0;
  margin: 0;
  overflow-x: auto;
  min-width: 765px;
  background-color: #F5F9F5;
}

a {
  user-select: text !important;
  color: #279A0E;
  text-decoration: none;
}

.text-disabled {
  color: #777777;
}

.ellipsis-line {
  max-width: 100%;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ellipsis-width {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #279A0E;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

@-moz-document url-prefix() {
  html {
    scrollbar-width: thin;
    scrollbar-color: #279A0E #f1f1f1;
  }
}

.navbar-header .dropdown-toggle::after {
  display: none !important;
}

.w-fit {
  width: fit-content;
}

.shadowRight {
  box-shadow: 0 0 6px #dedede;
  clip-path: inset(0px -6px 0px 0px);
  z-index: 1;
}

.shadowLeft {
  box-shadow: 0 0 6px #dedede;
  clip-path: inset(0px 0px 0px -6px);
}

.shadowTop {
  box-shadow: 0 0 6px #dedede;
  clip-path: inset(-6px 0px 0px 0px);
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-24 {
  font-size: 24px;
}

.mb-0 {
  margin-bottom: 0
}

.p-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.dark-text {
  color: #424242 !important;
}

.snackbar {
  padding-left: 30px !important;
  display: flex;
  justify-content: end;
  width: 60% !important;
  right: 18px !important;
}

.snackbar-title {
  color: #424242 !important;
}

.snack-bar-success {
  background-color: #ECFDF3 !important;
  border: 1px solid #43A047 !important;
  color: #47B881 !important;

  .snack-bar-action-button {
    border: 1px solid #43A047;
    background-color: #ECFDF3 !important;
    color: #47B881 !important;
    border-radius: 50%;
  }
}

.snack-bar-error {
  width: fit-content;
  background-color: #FDECEB !important;
  border: 1px solid #E53935 !important;
  color: #E53935 !important;

  .snack-bar-action-button {
    border: 1px solid #E53935;
    background-color: #FDECEB !important;
    color: #E53935 !important;
    border-radius: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .snackbar {
    width: 100% !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.blur-content {
  filter: blur(10px);
  pointer-events: none;
}