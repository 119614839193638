.dropdown-menu.show {
    overflow: hidden;
}

.dropdown-menu {
    padding: 4px;
    border-radius: 4px;
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.hideDropdownIcon::after {
    display: none !important;
}

.dropdown-item {
    gap: 10px;
    color: #424242;
    display: flex;
    padding: 10px 16px;
    align-items: center;
    border-radius: 4px;
}

.dropdown-item:focus {
    color: #424242;
    background: #F5F5F5 !important;
}

.btn-outline-secondary,
.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
    gap: 10px;
    color: #424242 !important;
    display: flex;
    padding: 12px 10px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #E0E0E0 !important;
    background-color: transparent !important;
}

.dropdown-toggle[aria-expanded=true]::after {
    position: relative;
    top: 2px;
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 2px solid #424242;
    border-top: 2px solid #424242;
    border-bottom: unset;
    border-left: unset;
    transform: rotate(-45deg);
    margin-right: 0.2em;
    margin-left: 0.2em;
}

.dropdown-toggle[aria-expanded=false]::after {
    position: relative;
    top: -2px;
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 2px solid #424242;
    border-top: 2px solid #424242;
    border-bottom: unset;
    border-left: unset;
    transform: rotate(135deg);
    margin-right: 0.2em;
    margin-left: 0.2em;
}