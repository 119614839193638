.navbar {
    height: 58px;
    color: #4b5563;
    background: #f9fafb;
    border: 1px solid #e5e7eb;
    padding: 6px 12px !important;
    box-shadow: 2px 2px 30px 0px #0000000F;
    z-index: 1000;
}

.profileAvatar {
    width: 40px;
    height: 40px;
    display: flex;
    color: #279A0E;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background-color: #EAF5E7;
}

.profileName {
    font-weight: 500;
    color: #424242;
}

.quickQuestionAddButton {
    height: 36px;
    color: #279A0E;
    font-weight: 500;
    text-decoration: none;
    border: 1.5px solid #279A0E;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
}