.pageContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #F5F9F5;
}

.pageBodyContainer {
    padding: 32px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.pageBody {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #fff;
    height: 100%;
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.errorImage{
    width: 100%;
    max-width: 700px;
    height: auto;
}

.errorTextWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 16px;
}

.errorText {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #424242;
}

.errorDescription {
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: #777777;
}

.goBackButton {
    outline: 0;
    border: 0;
    background-color: #279A0E;
    color: #fff;
    border-radius: 4px;
    padding: 11px 24px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Poppins';
    text-decoration: none;

    &:hover {
        background-color: #1E7A0A;
    }
}

@media screen and (max-width: 599px) {
    .errorText {
        font-size: 18px;
        line-height: 24px;
    }
    
    .errorDescription {
        line-height: 21px;
        font-size: 14px;
    }
    .goBackButton {
        font-size: 14px;
    }
    .pageBodyContainer {
        padding: 24px;

    }
}